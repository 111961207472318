<template>
  <div>
    <label for="site">Select Project</label>
    <infinite-scroll-select  
      @input="emitSelectedSite" 
      v-model="selectedSite"
      :showValidationError="!isValidSiteSelection"
      :value="selectedSite"
      :apiEndPoint="apiEndPoint"
      :itemsPerPage="itemsPerPage"
      siteType="star"
      >
    </infinite-scroll-select>

    <p v-show="!submitting" class="form__submission">
      <button @click="submit" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Create</button>
      <button @click="cancel" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>

    <p class="padding-top light">Require further information on the reports IBAT offers? Head over to our <a href="/sample-downloads" target="_blank">examples page</a> for a detailed explanation of each report and a downloadable example.</p>
  </div>
</template>

<script>
  import { mixinFormReports } from '../../mixins/mixin-form-reports'
  import InfiniteScrollSelect from '../form_fields/infiniteScrollSelect'
  export default {
    name: 'form-create-star-report',

    mixins: [mixinFormReports],

    components: {InfiniteScrollSelect },

    props: {
      defaultSite: { type: [Object, String], required: false , default: "" },
      itemsPerPage : {type : Number, default: 100},
      apiEndPoint:{
        type:String , required: true 
      }
    },

    data() {
      return {
        selectedSite: this.defaultSite || "",
      }
    },

    computed: {
      isValidSiteSelection() {
        return this.hasSelected(this.selectedSite)
      },

      formComplete() {
        return this.isValidSiteSelection
      },

      reportParams() {
        return {
          name: '',
          report_type_id: 'StarReport',
          site_ids: this.selectedSite.id,
        }
      }
    }
  }
</script>
