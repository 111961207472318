<template>
  <div
    v-show="matchesSearch"
    @click="toggleFilter"
    class="filters__filter"
    :class="{ 'filter--active': isActive }"
  >
    <div class="filters__filter-container flex flex-v-center">
      <i
        v-if="colour"
        class="filters__filter-colour"
        :style="{ backgroundColor: colour }"
      ></i>
      {{ name }}
    </div>

    <div v-if="legendUrl" class="filters__filter-legend">
      <div class="filters__filter-legend__image">
        <img :src="legendUrl" />
      </div>

      <div class="filters__filter-legend__labels flex flex-h-between">
        <span>Low</span>
        <span>High</span>
      </div>
    </div>
  </div>
</template>

<script>
import { eventHub } from '../../ibat.js';
import { mixinCarto } from '../../mixins/mixin-carto.js';
import { mixinResponsive } from '../../mixins/mixin-responsive.js';
import { mixinLoggedIn } from '../../mixins/mixin-logged-in';

import * as turf from '@turf/turf';

const KBA_URL = 'http://www.keybiodiversityareas.org/site/factsheet';

const TOGGLE_FILTER_EVENT_NAME = 'toggleFilter';
const RELOAD_LAYERS_EVENT_NAME = 'reloadLayers';

export default {
  name: 'layer',

  mixins: [mixinCarto, mixinResponsive, mixinLoggedIn],

  props: {
    id: {
      required: true,
      type: Number
    },
    name: {
      required: true,
      type: String
    },
    datasetName: String,
    reportsCount: {
      type: Number
    },
    allSelected: {
      required: true,
      type: Boolean
    },
    event: {
      required: true,
      type: String
    },
    colour: String,
    geometry: Object,
    cartoUsername: String,
    cartoAPIKey: String,
    filter: String,
    pointTable: String,
    polyTable: String,
    layerType: String,
    serviceUrl: String,
    mapFilters: Array,
    legendUrl: String,
    accordionItemActive: Boolean,
    mutuallyExclusiveGroupId: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      selected: false,
      layerAdded: false,
      popup: null,
      sitepopup: null,
      tables: [this.polyTable, this.pointTable],
      map: {},
      enablePopup: true,
      matchesSearch: true
    };
  },

  created() {
    eventHub.$on(TOGGLE_FILTER_EVENT_NAME, this.onToggleFilterEvent);

    if (this.user) {
      if (!this.user.paidPlan) {
        eventHub.$on('resetProjects', this.onResetProjects);
        eventHub.$on('resetLayers', this.onResetLayers);
      }
    } else {
      eventHub.$on('resetLayers', this.onResetLayers);
    }
  },

  mounted() {
    this.map = this.$store.state.map.map;

    eventHub.$on(RELOAD_LAYERS_EVENT_NAME, this.reloadLayer);

    this.loadLayer(true);

    if (this.toggleId != '') {
      eventHub.$on(this.toggleId, this.hide);
    }
  },

  destroyed() {
    eventHub.$off(RELOAD_LAYERS_EVENT_NAME, this.reloadLayer);
    eventHub.$off(TOGGLE_FILTER_EVENT_NAME, this.onToggleFilterEvent);
  },

  watch: {
    allSelected() {
      if (this.matchesSearch) {
        // Don't update status if layer was individually enabled or disabled
        // before clicking on "Select all" or "Hide all"
        if (
          (!this.allSelected && !this.selected) ||
          (this.allSelected && this.selected)
        ) {
          return;
        }

        this.selected = this.allSelected;
        this.toggleLayer();

        this.$store.commit('map/updateActiveSites', this.id);
      }
    },

    accordionItemActive() {
      if (!this.accordionItemActive) {
        this.toggleFilter(false);
      }

      if (this.user) {
        if (!this.user.paidPlan) {
          eventHub.$emit('resetProjects');
        }
      }
    },

    matchesSearch(matchesSearch) {
      if (!matchesSearch && this.selected) {
        this.selected = false;
        this.toggleLayer(false);
      }
    }
  },

  computed: {
    centroid() {
      let centroid = turf.centroid(this.geometry);
      let coords = centroid.geometry.coordinates;

      let output = (output = [
        Number(coords[1].toFixed(2)),
        Number(coords[0].toFixed(2))
      ]);

      return output.join(', ');
    },

    isActive() {
      return this.matchesSearch && this.selected;
    }
  },

  methods: {
    setSitePopup(sitepopup) {
      this.sitepopup = sitepopup;
    },

    reloadLayer() {
      this.loadLayer(true);
    },

    loadLayer(forceAdd) {
      this.layerAdded = false;

      if (this.$store.state.map.activeSites.indexOf(this.id) > -1) {
        this.selected = true;

        if (this.map.isStyleLoaded() || forceAdd) {
          this.toggleLayer(false, forceAdd);
        } else {
          this.map.on('style.load', () => {
            const checkMap = () => {
              if (!this.map.isStyleLoaded()) {
                setTimeout(checkMap, 300);
              } else {
                this.toggleLayer(false, forceAdd);
              }
            };

            checkMap();
          });
        }
      }
    },

    onToggleFilterEvent({ id, mutuallyExclusiveGroupId }) {
      // untoggle the filter if the event:
      if (
        // it is not the same filter
        this.id !== id &&
        // is selected
        this.selected === true &&
        // has a mutually exclusive group id (meg-id)
        typeof this.mutuallyExclusiveGroupId === 'number' &&
        // the meg-id is the same
        this.mutuallyExclusiveGroupId === mutuallyExclusiveGroupId
      ) {
        setTimeout(() => {
          this.toggleFilter(false);

          // let the user know that we are untoggling this filter
          eventHub.$emit('showFlashMessage', {
            type: 'notice',
            message: `"${this.name}" has been untoggled.`,
            timeout: 5000
          });
        });
      }
    },

    onResetProjects() {
      const active_layers = document.getElementsByClassName('filter--active');
      Array.from(active_layers).forEach((child) => {
        if (!child.closest('.accordion--standard')) {
          child.classList.remove('filter--active');
          child.__vue__.toggleFilter(false);
        }
      });
      if (this.sitepopup) {
        this.sitepopup.remove();
      }
    },

    onResetLayers() {
      const active_layers = document.getElementsByClassName('filter--active');
      Array.from(active_layers).forEach((child) => {
        if (child.closest('.accordion--standard')) {
          child.classList.remove('filter--active');
          child.__vue__.hideLayer();

          var accordion_standard_id = child.closest('.accordion--standard')
            .__vue__.id;

          child
            .closest('.accordion--standard')
            .__vue__.toggleAccordionContent(accordion_standard_id);

          var accordion_radio_id =
            child.closest('.accordion--radio').__vue__.id;

          child
            .closest('.accordion--radio')
            .__vue__.toggleAccordionContent(accordion_radio_id);
        }
      });
    },

    /**
     * Used to select this layer/filter, whatever we are calling it
     */
    toggleFilter(boolean) {
      if (this.user) {
        if (!this.user.paidPlan) {
          if (this.geometry) {
            eventHub.$emit('resetLayers');
          } else {
            eventHub.$emit('resetProjects');
          }
        }
      } else {
        if (this.geometry) {
          eventHub.$emit('resetLayers');
        }
      }

      if (typeof boolean == 'boolean') {
        this.selected = boolean;
      } else {
        this.selected = !this.selected;
      }

      this.toggleLayer(true);
      this.$store.commit('map/updateActiveSites', this.id);

      this.emitToggleFilterOnId();
    },

    emitToggleFilter() {
      eventHub.$emit(TOGGLE_FILTER_EVENT_NAME, {
        id: this.id,
        mutuallyExclusiveGroupId: this.mutuallyExclusiveGroupId
      });
    },

    emitToggleFilterOnId() {
      eventHub.$emit(this.event);
    },

    toggleLayer(pan, forceAdd) {
      if (this.user) {
        if (this.allSelected && !this.user.paidPlan) {
          if (this.geometry) {
            eventHub.$emit('resetLayers');
          } else {
            eventHub.$emit('resetProjects');
          }
        }
      }

      if (!this.layerAdded || forceAdd) {
        this.createLayer();
      }

      if (this.selected) {
        this.showLayer(pan);
        setTimeout(() => this.emitToggleFilter());
      } else {
        this.hideLayer();
      }
    },

    getLayerName() {
      return `vfilter-layer_${this.id}_${this.name}`;
    },

    createLayer() {
      const layerName = this.getLayerName();

      this.layerAdded = true;

      let layers = this.map.getStyle().layers;
      let firstSymbolId;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {
          firstSymbolId = layers[i].id;
          break;
        }
      }

      if (this.geometry) {
        this.map.addSource(layerName, {
          type: 'geojson',
          data: this.geometry
        });

        this.map.addLayer({
          id: `${layerName}-points`,
          type: 'circle',
          source: layerName,
          paint: {
            'circle-radius': 6,
            'circle-color': '#B42222'
          },
          filter: ['==', '$type', 'Point'],
          layout: {
            visibility: 'none'
          }
        });

        this.map.addLayer(
          {
            id: `${layerName}-polygons`,
            type: 'fill',
            source: layerName,
            paint: {
              'fill-color': '#B42222',
              'fill-opacity': 0.8
            },
            filter: ['==', '$type', 'Polygon'],
            layout: {
              visibility: 'none'
            }
          },
          firstSymbolId
        );

        this.map.addLayer({
          id: `${layerName}-lines`,
          type: 'line',
          source: layerName,
          paint: {
            'line-color': '#B42222',
            'line-width': 2
          },
          filter: ['==', '$type', 'LineString'],
          layout: {
            visibility: 'none'
          }
        });

        if (!this.isTouch()) {
          this.map.on('click', `${layerName}-points`, (e) => {
            this.showSitePopup(e);
          });

          this.map.on('mouseenter', `${layerName}-points`, () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });

          this.map.on('mouseleave', `${layerName}-points`, () => {
            this.map.getCanvas().style.cursor = '';
          });

          this.map.on('click', `${layerName}-polygons`, (e) => {
            this.showSitePopup(e);
          });

          this.map.on('mouseenter', `${layerName}-polygons`, () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });

          this.map.on('mouseleave', `${layerName}-polygons`, () => {
            this.map.getCanvas().style.cursor = '';
          });

          this.map.on('click', `${layerName}-lines`, (e) => {
            this.showSitePopup(e);
          });

          this.map.on('mouseenter', `${layerName}-lines`, () => {
            this.map.getCanvas().style.cursor = 'pointer';
          });

          this.map.on('mouseleave', `${layerName}-lines`, () => {
            this.map.getCanvas().style.cursor = '';
          });
        }
      } else if (this.layerType === 'raster') {
        const tilesEndpoint = this.serviceUrl;

        this.map.addSource(layerName, {
          type: 'raster',
          tiles: [tilesEndpoint],
          tileSize: 256
        });

        this.map.addLayer(
          {
            id: `${layerName}-raster`,
            type: 'raster',
            source: layerName,
            paint: {
              'raster-resampling': 'nearest'
            }
          },
          firstSymbolId
        );
      } else if (this.serviceUrl) {
        const host = window.location.protocol + '//' + window.location.host;

        this.addVectorLayer([host + this.serviceUrl], firstSymbolId, this.mapFilters)
      } else if (this.cartoUsername && this.cartoAPIKey) {
        const tiles = this.createTiles(this.cartoUsername, this.cartoAPIKey);

        tiles.getTiles((_object) => {
          // For Carto we pass the filter SQL to carto to generate tiles with that filter baked in, hence passing the empty array here.
          // For our own map server (`serviceUrl`) we get the full vector layer and filter client side (using `mapFilters`).
          this.addVectorLayer(tiles.mapProperties.mapProperties.metadata.tilejson.vector.tiles, firstSymbolId, [])
        });
      }

      this.map.on('draw.modechange', (e) => {
        switch (e.mode) {
          case 'direct_select':
          case 'draw_line_string':
          case 'draw_polygon':
          case 'draw_point':
            this.enablePopup = false;
            break;
          default:
            setTimeout(() => {
              this.enablePopup = true;
            }, 0);
            break;
        }
      });
    },

    addVectorLayer(tiles, firstSymbolId, filters) {
      const layerName = this.getLayerName();

      this.map.addSource(`${layerName}-source`, {
        type: 'vector',
        tiles: tiles
      })

      this.map.addLayer(
        {
          id: `${layerName}-polygons`,
          type: 'fill',
          source: `${layerName}-source`,
          filter: ['all'].concat(filters),
          'source-layer': 'layer0',
          paint: {
            'fill-color': this.colour
            ? this.colour
            : `#${(Math.random().toString(16) + '000000').substring(
              2,
              8
            )}`,
            'fill-opacity': 0.8
          },
          layout: {
            visibility: 'none'
          }
        },
        firstSymbolId
      );

      this.map.addLayer(
        {
          id: `${layerName}-points`,
          type: 'circle',
          source: `${layerName}-source`,
          'source-layer': 'layer0',
          paint: {
            'circle-radius': 6,
            'circle-color': this.colour
            ? this.colour
            : `#${(Math.random().toString(16) + '000000').substring(
              2,
              8
            )}`,
            'circle-stroke-width': 1,
            'circle-stroke-opacity': 0.8,
            'circle-stroke-color': '#FFFFFF'
          },
          filter: ['all', ['==', '$type', 'Point']].concat(filters),
          layout: {
            visibility: 'none'
          }
        },
        firstSymbolId
      );

      if (!this.isTouch()) {
        this.map.on('click', `${layerName}-polygons`, (e) => {
          this.addPopup(e);
        });

        this.map.on('click', `${layerName}-points`, (e) => {
          this.addPopup(e);
        });

        this.map.on('touchend', `${layerName}-polygons`, (e) => {
          this.addPopup(e);
        });

        this.map.on('touchend', `${layerName}-points`, (e) => {
          this.addPopup(e);
        });

        this.map.on('mouseenter', `${layerName}-polygons`, () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseenter', `${layerName}-points`, () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', `${layerName}-polygons`, () => {
          this.map.getCanvas().style.cursor = '';
        });

        this.map.on('mouseleave', `${layerName}-points`, () => {
          this.map.getCanvas().style.cursor = '';
        });
      }

      this.toggleLayer();
    },

    addPopup(e) {
      if (!this.enablePopup) {
        return;
      }

      if (this.popup) {
        this.popup.remove();
      }

      const properties = e.features[0].properties,
        url = `${KBA_URL}/${properties.sitrecid}`,
        link = `<a href="${url}" target="_blank" title="View factsheet for ${this.datasetName}">${properties.intname}</a>`,
        titleHTML = properties.sitrecid ? link : properties.intname;

      let popupHTML = JSON.stringify(properties);
      if (this.isWDPA()) {
        popupHTML = `<h1>${this.datasetName}</h1>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
                <th>Governance</th>
                <th>IUCN Management</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><a href="https://protectedplanet.net/${properties.wdpaid}" title="${properties.name} | Protected Planet" target="_blank">${properties.name}</a></td>
                <td>${properties.desig_eng}</td>
                <td>${properties.gov_type}</td>
                <td>${properties.iucn_cat}</td>
              </tr>
            </tbody>
          </table>`;
      } else if (this.isKBA()) {
        popupHTML = `<h1>${this.datasetName}</h1>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th class="center">IBA</th>
                <th class="center">AZE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${titleHTML}</td>
                <td class="center">${this.checkStatus(
                  properties.IbaStatus
                )}</td>
                <td class="center">${this.checkStatus(
                  properties.AzeStatus
                )}</td>
              </tr>
            </tbody>
          </table>`;
      }

      let popup = new mapboxgl.Popup({
        className: 'mapboxgl-popup-layers'
      })
        .setLngLat(e.lngLat)
        .setHTML(popupHTML)
        .addTo(this.map);

      if (!this.map.popupLayers) {
        this.map.popupLayers = {};
      }

      // Disable multiple popups for non-WDPA layers
      if (!this.isWDPA() && this.map.popupLayers[this.datasetName]) {
        this.map.popupLayers[this.datasetName].remove();
      }

      this.map.popupLayers[this.datasetName] = popup;

      this.popup = popup;
    },

    isWDPA() {
      return (
        (this.polyTable && this.polyTable.indexOf('wdpa') !== -1) ||
        (this.pointTable && this.pointTable.indexOf('wdpa') !== -1)
      );
    },

    isKBA() {
      return (
        (this.polyTable && this.polyTable.indexOf('kba') !== -1) ||
        (this.pointTable && this.pointTable.indexOf('kba') !== -1)
      );
    },

    showLayer(pan) {
      const layerName = this.getLayerName();

      if (this.map.getLayer(`${layerName}-points`)) {
        this.map.setLayoutProperty(
          `${layerName}-points`,
          'visibility',
          'visible'
        );
      }

      if (this.map.getLayer(`${layerName}-polygons`)) {
        this.map.setLayoutProperty(
          `${layerName}-polygons`,
          'visibility',
          'visible'
        );
      }

      if (this.map.getLayer(`${layerName}-lines`)) {
        this.map.setLayoutProperty(
          `${layerName}-lines`,
          'visibility',
          'visible'
        );
      }

      if (this.map.getLayer(`${layerName}-raster`)) {
        this.map.setLayoutProperty(
          `${layerName}-raster`,
          'visibility',
          'visible'
        );
      }

      if (this.geometry && pan) {
        let centroid = turf.centroid(this.geometry);
        this.map.panTo(centroid.geometry.coordinates);
      }
    },

    hideLayer() {
      const layerName = this.getLayerName();

      if (this.map.getLayer(`${layerName}-points`)) {
        this.map.setLayoutProperty(`${layerName}-points`, 'visibility', 'none');
      }

      if (this.map.getLayer(`${layerName}-polygons`)) {
        this.map.setLayoutProperty(
          `${layerName}-polygons`,
          'visibility',
          'none'
        );
      }

      if (this.map.getLayer(`${layerName}-lines`)) {
        this.map.setLayoutProperty(`${layerName}-lines`, 'visibility', 'none');
      }

      if (this.map.getLayer(`${layerName}-raster`)) {
        this.map.setLayoutProperty(`${layerName}-raster`, 'visibility', 'none');
      }
    },

    showSitePopup(e) {
      if (!this.enablePopup) {
        return;
      }

      let sitepopup = new mapboxgl.Popup({
        className: 'mapboxgl-popup-sites',
        offset: 5
      })
        .setLngLat(e.lngLat)
        .setHTML(
          `<strong>${this.name}</strong><br>${this.centroid}<br><div class="button-wrapper button-wrapper--map-popup"><a href="/sites/${this.id}"><button class="button--cta button--small">View site</button></a></div>`
        )
        .addTo(this.map);

      this.setSitePopup(sitepopup);
    },

    checkStatus(status) {
      let icon = status === 'confirmed' ? 'tick' : 'close';
      return `<i class="icon-${icon}"></i>`;
    }
  }
};
</script>
