<template>
  <div class="map-wrapper full-height">
    <div :id="id" class="map full-height"></div>
  </div>
</template>

<script>
  import { eventHub } from '../../ibat.js'
  import * as turf from '@turf/turf'

  import { mixinResponsive } from '../../mixins/mixin-responsive.js'
  import { mixinMapbox, mixinMapboxInfo } from '../../mixins/mixin-mapbox.js'

  export default {
    name: 'mapbox-countries',

    mixins: [ mixinMapbox, mixinMapboxInfo, mixinResponsive ],

    props: {
      id: {
        type: String,
        required: true
      },
      geojson: {
        type: Object,
        required: true
      },
      datasets: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        mapboxControls: []
      }
    },

    computed: {
      firstSymbolId() {
        var layers = this.map.getStyle().layers;
        // Find the index of the first symbol layer in the map style
        var firstSymbolId;
        for (var i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol') {
            firstSymbolId = layers[i].id;
            break;
          }
        }

        return firstSymbolId
      }
    },

    methods: {
      resizeMap () {
        setTimeout(() => { this.map.resize() }, 100)
      }
    },

    mounted () {
      eventHub.$on('resizeMap', this.resizeMap)

      // This should probably be loaded from the back-end
      const datasetLookup = {
        wdpa_public: [
          {
            name: "marine-protected-areas",
            clause: "WHERE marine IN ('1', '2')",
            color: '#389cd7'
          },
          {
            name: "terrestrial-protected-areas",
            clause: "WHERE marine = '0'",
            color: '#64bf78'
          }
        ],
        kba: [
          {
            name: "key-biodiversity-areas",
            clause: '',
            color: '#d16795'
          }
        ]
      }

      this.map.on('load', () => {
        this.datasets.forEach(dataset => {
          datasetLookup[dataset.slug].forEach(layer => {
            let sql = `SELECT * FROM ${dataset.poly_table} ${layer.clause}`

            let tiles = new cartodb.Tiles({
              user_name: dataset.carto_username,
              tiler_protocol: 'https',
              tiler_port: '443',
              sublayers: [{
                sql: sql,
                cartocss: '#layer { marker-fill: #F0F0F0; }'
              }],
              extra_params: { map_key: dataset.carto_api_key }
            })


            tiles.getTiles(object => {
              this.map.addLayer({
                'id': `${dataset.slug}-${layer.name}`,
                'type': 'fill',
                'source': {
                  'type': 'vector',
                  'tiles': tiles.mapProperties.mapProperties.metadata.tilejson.vector.tiles
                },
                'source-layer': 'layer0',
                'paint': {
                  'fill-color': layer.color,
                  'fill-opacity': 0.6,
                },
                'filter': ['==', '$type', 'Polygon']
              }, this.firstSymbolId)
            })
          })
        })

        let bbox = turf.bbox(this.geojson)

        this.map.fitBounds(bbox, { animate: false })
      });
    }
  }
</script>
