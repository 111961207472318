<template>
  <div>
    <label for="report">Report Name</label>
    <text-input
      name="report"
      id="report"
      :showValidationError="!isValidReportName"
      classes="report-form"
      v-model="reportName">
    </text-input>

    <infinite-scroll-select
      @input="emitSelectedSite"
      v-model="selectedSites"
      :showValidationError="!isValidSitesSelection"
      :value="selectedSites"
      :apiEndPoint="apiEndPoint"
      :itemsPerPage="itemsPerPage"
      siteType="tnfd"
      :selectMultiple="true"
      :optionsLimit="sitesOptionsLimit"
      >
    </infinite-scroll-select>

    <p v-show="!submitting" class="form__submission">
      <button @click="submit" class="button--outline margin-space-right" :class="{ 'button--disabled': !formComplete }" :disabled="!this.formComplete">Create</button>
      <button @click="cancel" class="button--underline">Cancel</button>
    </p>

    <i v-show="submitting" class="icon--spinner"></i>

    <p class="padding-top light">Require further information on the reports IBAT offers? Head over to our <a href="/sample-downloads" target="_blank">examples page</a> for a detailed explanation of each report and a downloadable example.</p>
  </div>
</template>

<script>
  import MultiSearchSelect from '../form_fields/MultiSearchSelect'
  import DecimalInput from '../form_fields/DecimalInput'
  import TextInput from '../form_fields/TextInput'
  import { mixinFormReports } from '../../mixins/mixin-form-reports'
  import InfiniteScrollSelect from '../form_fields/infiniteScrollSelect'

  export default {
    name: 'form-create-tnfd-locate-report',

    mixins: [mixinFormReports],

    components: {
      MultiSearchSelect,
      DecimalInput,
      TextInput,
      InfiniteScrollSelect
    },

    props: {
      defaultSite: { type: [Object, String, Array], required: false , default: "" },
      portfolioId: { type: Number },
      itemsPerPage : {type : Number, default: 10},
      apiEndPoint:{
        type:String , required: true
      },
      sitesOptionsLimit: {
        type: Number,
        default: 0
      },
    },

    data() {
      return {
        selectedSites: [],
        reportName: '',
        minimumSites: 1,
        maxDecimalPlaces: 2,
        regex: /\/|\\/
      }
    },

    computed: {
      isValidSitesSelection() {
        return this.isValidLength(this.selectedSites, this.minimumSites);
      },

      isValidReportName() {
        return this.isValidLength(this.reportName, 1)
          && this.isValidString(this.reportName, this.regex)
      },

      assignSites() {
        this.selectedSites =  this.defaultSite ;
      },

      formComplete() {
        return this.isValidSitesSelection && this.isValidReportName
      },

      iconClass () {
        return this.clearable ? 'close' : 'plus';
      },

      reportParams() {
        return {
          name: this.reportName,
          report_type_id: 'TnfdReport',
          site_ids: this.selectedSites.map(site => site.id),
          portfolio_id: this.portfolioId
        }
      }
    }
  }
</script>
